<template>
    <!-- begin:: Header Topbar -->
    <div class="topbar">
        <!--begin: User Bar -->
        <KTQuickUser></KTQuickUser>
        <!--end: User Bar -->
    </div>
    <!-- end:: Header Topbar -->
</template>

<script>
    import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
    import i18nService from "@/core/services/i18n.service.js";

    export default {
        name: "KTTopbar",
        data() {
            return {
                languageFlag: "",
                languages: i18nService.languages
            };
        },
        components: {
            KTQuickUser,
        },
        methods: {
            onLanguageChanged() {
                this.languageFlag = this.languages.find(val => {
                    return val.lang === i18nService.getActiveLanguage();
                }).flag;
            }
        },
        computed: {
            getLanguageFlag() {
                return this.onLanguageChanged();
            }
        }
    };
</script>
