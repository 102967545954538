<template>
    <div v-if="getEventByUserList" class="mt-5">
        <div class="text-left">
            <h5 v-if="getEventByUserList" class="mb-2">Your Events</h5>

            <div class="text-primary" v-if="getActiveEvent">selected Event is :
                {{ getActiveEvent.title }}
            </div>
            <div
                v-if="$global.hasPermission('selectedownereventsview') || $global.hasPermission('selectedeventsview')">
                <button
                    :disabled="getActiveEvent ? event.id === getActiveEvent.id : false"
                    @click="switchEventReload(event.id)"
                    class="btn btn-success btn-sm mr-2 mb-1"
                    v-for="(event,index) in getEventByUserList"
                    :key="index">
                    {{ event ? event.title : "" }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {getEventId, setEventId} from "@/util/globalFunction";
import {request} from "@/core/services/Request";
import {mapGetters, mapState} from "vuex"
import {bus} from "../../../../../main";
import JwtService from "@/core/services/jwt.service";

export default {
    data() {
        return {}
    },
    mounted() {
        this.$store.dispatch('getEventByUserList')

        bus.$on("check-selected-event", () => {
            this.$store.dispatch('getEventByUserList')
        })

        if (getEventId()) {
            this.getSwitchEvent(getEventId())
        }

    },
    methods: {
        switchEventReload(id) {
            setEventId(id)
            window.location.href = process.env.BASE_URL
        },
        getSwitchEvent(Id) {
            if (Id) {
                request({
                    url: `/events/switch`,
                    method: 'post',
                    data: {
                        event_id: Id,
                    }
                })
                    .then((response) => {
                        const {data} = response.data
                        if (data.user) {
                            const {access_token} = data.user
                            if (access_token) {
                                JwtService.saveToken(JSON.stringify(data.user));
                            }
                        }
                        this.$store.dispatch('getActiveEvent', data.event)
                    })
                    .catch((error) => {
                    })
            }
        },
    },
    computed: {
        ...mapGetters({
            getActiveEvent: 'getActiveEvent',
            getEventList: 'getEventList',
            getEventByUserList: 'getEventByUserList'
        }),
        ...mapState([
            'global',
        ]),
    }
}
</script>
