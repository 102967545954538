<template>
    <div>
        <ul class="menu-nav">
            <router-link
                to="/dashboard"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Dashboard </span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-show="$global.hasPermission('leadsview')"
                to="/leads"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Leads </span>
                    </a>
                </li>
            </router-link>
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu menu-item-open-dropdown"
                v-bind:class="{ 'menu-item-active': hasActiveChildren(['/events','/vendors','/todo/lists','/itineraries','/groups','/bounce/events']) }"
            >
                <a href="#" class="menu-link menu-toggle">
                    <span class="menu-text"> Events </span>
                </a>
                <div class="menu-submenu menu-submenu-classic">
                    <ul class="menu-subnav">
                        <router-link
                            v-show="$global.hasPermission('eventsview')"
                            to="/events"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Events </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-show="$global.hasPermission('vendorsview')"
                            to="/vendors"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Vendors </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-show="$global.hasPermission('todolistsview')"
                            to="/todo/lists"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> To-Do-List </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('itinerariesview')"
                            to="/itineraries"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Event Schedule</span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('groupsview')"
                            to="/groups"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text">Manage Group</span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-show="$global.hasPermission('bounceeventsview')"
                            to="/bounce/events"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text">Marketplace </span>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </li>
            <router-link
                v-show="$global.hasPermission('calendarsview')"
                to="/calendar"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Calendar </span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-show="$global.hasPermission('quotationsview')"
                to="/quotations"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Quotation </span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-show="$global.hasPermission('quotationbookingsview')"
                to="/bookings"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Booking </span>
                    </a>
                </li>
            </router-link>
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu menu-item-open-dropdown"
                v-bind:class="{ 'menu-item-active': hasActiveChildren(['/users','/roles']) }"
            >
                <a href="#" class="menu-link menu-toggle">
                    <span class="menu-text"> Users </span>
                </a>
                <div class="menu-submenu menu-submenu-classic">
                    <ul class="menu-subnav">
                        <router-link
                            v-show="$global.hasPermission('rolesview')"
                            to="/roles"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-text"> Role </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-show="$global.hasPermission('usersview')"
                            to="/users"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-text"> User </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-show="$global.hasPermission('subusersview') && $global.hasRole('SuperUser')"
                            to="/sub/users"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                data-menu-toggle="hover"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <span class="menu-text"> SubUser </span>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </li>
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu menu-item-open-dropdown"
                v-bind:class="{ 'menu-item-active': hasActiveChildren(['/company/profiles','/properties','/halls','/decors','/fnb','/fnb/packages','/rooms','/term/conditions','/other/charges','/assets','/fnb/food/menus']) }"
            >
                <a href="#" class="menu-link menu-toggle">
                    <span class="menu-text"> Settings </span>
                </a>
                <div class="menu-submenu menu-submenu-classic">
                    <ul class="menu-subnav">
                        <router-link
                            v-if="$global.hasPermission('companyprofilesview') & $global.hasRole('SuperAdmin')"
                            to="/company/profiles"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Company Profiles </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('companyprofilesview') & $global.hasRole('SuperUser')"
                            to="/user/company/profiles"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Company Profiles </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('propertiesview')"
                            to="/properties"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Properties </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('hallsview')"
                            to="/halls"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Halls </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('decorsview')"
                            to="/decors"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Decors </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('fnbextrasview') || $global.hasPermission('fnbmenusview') || $global.hasPermission('fnbpackagesview')"
                            to="/fnb"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage F&B </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('roomsview')"
                            to="/rooms"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Rooms </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('termconditionsview')"
                            to="/term/conditions"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Term Conditions </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('otherchargesview')"
                            to="/other/charges"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Other Charges </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('assetsview')"
                            to="/assets"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Asset </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('guestroomsview')"
                            to="/guest/rooms"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Guest Rooms </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('invitationattachmentsview')"
                            to="/event/attachments"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text">Manage Invitations</span>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </li>
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu menu-item-open-dropdown"
                v-bind:class="{ 'menu-item-active': hasActiveChildren(['/food/menus/import','/import/leads','/brochures']) }"

                v-if="$global.hasPermission('leadsimport') || $global.hasPermission('fnbmenusimport')"
            >
                <a href="#" class="menu-link menu-toggle">
                    <span class="menu-text"> Import </span>
                </a>
                <div class="menu-submenu menu-submenu-classic">
                    <ul class="menu-subnav">
                        <router-link
                            v-if="$global.hasPermission('fnbmenusimport')"
                            to="/food/menus/import"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage FoodMenu Import </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('leadsimport')"
                            to="/import/leads"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Leads Import </span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('brochuresview')"
                            to="/brochures"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text"> Manage Brochures </span>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </li>
            <router-link
                v-show="$global.hasPermission('guestlistsview')"
                to="/guests"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Guest Lists </span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-show="$global.hasPermission('masterguestsview')"
                to="/guest/users"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text">Master Guest User </span>
                    </a>
                </li>
            </router-link>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "KTMenu",
        methods: {
            hasActiveChildren(match) {
                if (_.isArray(match)) {
                    if (_.filter(match, (i) => i === this.$route["path"])[0]) {
                        return _.filter(match, (i) => i === this.$route["path"])[0] !== -1;
                    }
                }
                return this.$route["path"].indexOf(match) !== -1;
            }
        }
    };
</script>
