<template>
    <div>
        <!--        <super-admin-menu v-if="$global.hasRole('SuperAdmin')"></super-admin-menu>-->
        <!--        <super-user-menu v-else-if="$global.hasRole('SuperUser')"></super-user-menu>-->
        <!--        <sub-user-menu v-else-if="$global.hasRole('SubUser')"></sub-user-menu>-->
        <!--        <sub-guest-user-menu v-else-if="$global.hasRole('SubGuestUser')"></sub-guest-user-menu>-->
        <!--        <master-guest-user-menu v-else-if="$global.hasRole('MasterGuestUser')"></master-guest-user-menu>-->
        <general-menu></general-menu>
    </div>
</template>

<script>
    import SuperAdminMenu from "./roleMenus/SuperAdminMenu";
    import SuperUserMenu from "./roleMenus/SuperUserMenu";
    import SubUserMenu from "./roleMenus/SubUserMenu";
    import SubGuestUserMenu from "./roleMenus/SubGuestUserMenu";
    import MasterGuestUserMenu from "./roleMenus/MasterGuestUserMenu";
    import GeneralMenu from "./roleMenus/GeneralMenu";


    export default {
        name: "KTMenu",
        components: {
            // SubGuestUserMenu,
            // MasterGuestUserMenu,
            // SuperAdminMenu,
            // SuperUserMenu,
            // SubUserMenu,
            GeneralMenu,
        },
        methods: {
            hasActiveChildren(match) {
                return this.$route["path"].indexOf(match) !== -1;
            }
        }
    };
</script>
