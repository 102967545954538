<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
    >
      <div class="d-flex flex-column text-right pr-3">
                <span class="text-white font-weight-bolder font-size-sm d-none d-md-inline">
                    {{ profileData ? profileData.name : "" }}
                </span>
        <span v-if="$global.hasPermission('selectedpropertiesview') && getActiveProperty">
                    <small>
                    {{ getActiveProperty.name }}
                    </small>
                </span>
        <!--                <span
                            v-if="($global.hasPermission('masterguestuser') || $global.hasPermission('subguestuser')) && getActiveEvent">
                            <small>
                                {{ getActiveEvent.title }}
                            </small>
                        </span>-->
      </div>
      <span class="symbol symbol-35">
            <span
              class="symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30">
                {{ profileData ? profileData.name.charAt(0).toUpperCase() : "" }}
            </span>
            </span>
    </div>
    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <div v-if="profile">
              <img class="symbol-label"
                   :src="profile"
                   alt="" />
              <i class="symbol-badge bg-success"></i>
            </div>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="/profile"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <a :href="href" :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active']"
                 @click="navigate">
                {{ profileData ? profileData.name : "" }}
              </a>
            </router-link>

            <div class="navi mt-2">
              <a href="#" class="navi-item">
                                <span class="navi-link p-0 pb-2">
                                  <span class="navi-icon mr-1">
                                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                                      <!--begin::Svg Icon-->
                                      <inline-svg src="media/svg/icons/Communication/Mail-notification.svg" />
                                      <!--end::Svg Icon-->
                                    </span>
                                  </span>
                                  <span class="navi-text text-muted text-hover-primary">
                                      {{ profileData ? profileData.email : "" }}
                                  </span>
                                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              <i class="fa fa-spin fa-spinner" v-show="loader"></i>
              Sign out
            </button>
          </div>
        </div>
        <div v-if="$global.hasPermission('walletsview')">
          <hr />
          <router-link
            to="/my/wallet"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a :href="href" :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active']"
               @click="navigate">
              My Wallet
            </a>
          </router-link>
        </div>
        <hr />
        <property
          v-if="$global.hasPermission('owncompanyprofilesview') || $global.hasPermission('companyprofilesview')"></property>
        <event
          v-if="$global.hasPermission('companyprofilesview') ||$global.hasPermission('masterguestuser') || $global.hasPermission('subguestuser')"></event>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from 'vuex';
import { DELETE_TOKEN } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import JwtService from "@/core/services/jwt.service";
import { getAuthUser } from "@/util/globalFunction";
import Property from "./panels/Property";
import Event from "./panels/Event";
import { request } from "@/core/services/Request";
import { getEventId, getPropertyId, removeStorageItem } from "@/util/globalFunction";

export default {
  name: "KTQuickUser",
  components: {
    Property,
    Event,
  },
  data() {
    return {
      profileData: null,
      property: [],
      profile: null,
      loader: false,
      events: [],
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.profileData = getAuthUser();
    this.profile = getAuthUser().profile_pic ? getAuthUser().profile_pic.download_url : "https://via.placeholder.com/100x100";
  },
  methods: {
    async onLogout() {
      this.loader = true;
      this.$store
      .dispatch(DELETE_TOKEN, JwtService.getToken())
      .then(() => {
      });


      removeStorageItem("event_id");
      removeStorageItem("property_id");

      try {
        const response = await request({
          url: 'auth/logout',
          method: 'post',
          data: JwtService.getToken(),
        });
        this.loader = false;
        this.$router.push({ name: "login" });
      } catch (error) {
      }

    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    ...mapGetters({
      getActiveProperty: 'getActiveProperty',
      getPropertyList: 'getPropertyList',
      getEventList: 'getEventList',
      getEventByUserList: 'getEventByUserList',
      getActiveEvent: 'getActiveEvent',
    })
  }
};
</script>
