var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Dashboard ")])])])]}}])}),(_vm.$global.hasPermission('eventsview'))?_c('router-link',{attrs:{"to":"/events"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Leads ")])])])]}}],null,false,1406909087)}):_vm._e(),((((!_vm.$global.hasPermission('owncompanyprofilesview') && _vm.$global.hasPermission('eventsview')) || (_vm.$global.hasPermission('masterguestuser') || _vm.$global.hasPermission('subguestuser'))) && (_vm.$global.hasPermission('vendorsview') ||  _vm.$global.hasPermission('todolistsview')
                || _vm.$global.hasPermission('itinerariesview') || _vm.$global.hasPermission('groupsview') || _vm.$global.hasPermission('bounceeventsview'))))?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(['/events','/vendors','/todo/lists','/itineraries','/groups','/bounce/events']) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('eventsview')),expression:"$global.hasPermission('eventsview')"}],attrs:{"to":"/events"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Events ")])])])]}}],null,false,4113663519)}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('vendorsview')),expression:"$global.hasPermission('vendorsview')"}],attrs:{"to":"/vendors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Vendors ")])])])]}}],null,false,447480535)}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('todolistsview')),expression:"$global.hasPermission('todolistsview')"}],attrs:{"to":"/todo/lists"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" To-Do-List ")])])])]}}],null,false,4069817426)}),(_vm.$global.hasPermission('itinerariesview'))?_c('router-link',{attrs:{"to":"/itineraries"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Event Schedule")])])])]}}],null,false,1500959656)}):_vm._e(),(_vm.$global.hasPermission('groupsview'))?_c('router-link',{attrs:{"to":"/groups"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Manage Group")])])])]}}],null,false,4171275007)}):_vm._e(),(_vm.$global.hasPermission('bounceeventsview') && _vm.$global.hasPermission('companyprofilesview'))?_c('router-link',{attrs:{"to":"/bounce/events"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Marketplace ")])])])]}}],null,false,3665381119)}):_vm._e()],1)])]):_vm._e(),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('calendarsview')),expression:"$global.hasPermission('calendarsview')"}],attrs:{"to":"/calendar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Calendar ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('quotationsview')),expression:"$global.hasPermission('quotationsview')"}],attrs:{"to":"/quotation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Quotation ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('quotationbookingsview')),expression:"$global.hasPermission('quotationbookingsview')"}],attrs:{"to":"/bookings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Booking ")])])])]}}])}),(_vm.$global.hasPermission('sharebounceeventsview') && _vm.$global.hasPermission('owncompanyprofilesview'))?_c('router-link',{attrs:{"to":"/spu/share/bounce/events"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" MarketPlace ")])])])]}}],null,false,1628637951)}):_vm._e(),(_vm.$global.hasPermission('subusersview') || _vm.$global.hasPermission('usersview') ||  _vm.$global.hasPermission('rolesview')
                || _vm.$global.hasPermission('masterguestsview') || _vm.$global.hasPermission('subguestusersview'))?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(['/users','/roles','/sub/users','/guest/users','/sub/guest/user','/guests']) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('rolesview')),expression:"$global.hasPermission('rolesview')"}],attrs:{"to":"/roles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Role ")])])])]}}],null,false,2696594676)}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('usersview')),expression:"$global.hasPermission('usersview')"}],attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" User ")])])])]}}],null,false,760699409)}),(_vm.$global.hasPermission('subusersview') && (!_vm.$global.hasPermission('companyprofilesview')))?_c('router-link',{attrs:{"to":"/sub/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" SubUser ")])])])]}}],null,false,1055555573)}):_vm._e(),(_vm.$global.hasPermission('masterguestsview')  && (!_vm.$global.hasPermission('companyprofilesview')))?_c('router-link',{attrs:{"to":"/guest/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Master Guest User ")])])])]}}],null,false,1774633277)}):_vm._e(),(_vm.$global.hasPermission('subguestusersview') && (!_vm.$global.hasPermission('companyprofilesview')))?_c('router-link',{attrs:{"to":"/sub/guest/user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Sub Guest User ")])])])]}}],null,false,595019877)}):_vm._e(),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('guestlistsview')),expression:"$global.hasPermission('guestlistsview')"}],attrs:{"to":"/guests"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Guest Lists ")])])])]}}],null,false,3849177313)})],1)])]):_vm._e(),(_vm.$global.hasPermission('companyprofilesview') || _vm.$global.hasPermission('owncompanyprofilesview') ||
                _vm.$global.hasPermission('propertiesview') ||  _vm.$global.hasPermission('hallsview') ||  _vm.$global.hasPermission('decorsview')
                || _vm.$global.hasPermission('fnbextrasview') || _vm.$global.hasPermission('fnbmenusview') || _vm.$global.hasPermission('fnbpackagesview')
                || _vm.$global.hasPermission('roomsview') || _vm.$global.hasPermission('termconditionsview') || _vm.$global.hasPermission('otherchargesview')
                || _vm.$global.hasPermission('assetsview') || _vm.$global.hasPermission('guestroomsview') || _vm.$global.hasPermission('invitationattachmentsview') || _vm.$global.hasPermission('otherchargesview'))?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(['/company/profiles','/properties','/halls','/decors','/fnb','/fnb/packages','/rooms','/term/conditions','/other/charges','/assets','/fnb/food/menus']) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.$global.hasPermission('companyprofilesview'))?_c('router-link',{attrs:{"to":"/company/profiles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Company Profiles ")])])])]}}],null,false,2572060799)}):_vm._e(),(_vm.$global.hasPermission('owncompanyprofilesview'))?_c('router-link',{attrs:{"to":"/user/company/profiles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Company Profiles ")])])])]}}],null,false,1236061374)}):_vm._e(),(_vm.$global.hasPermission('propertiesview'))?_c('router-link',{attrs:{"to":"/properties"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Properties ")])])])]}}],null,false,2118467585)}):_vm._e(),(_vm.$global.hasPermission('hallsview'))?_c('router-link',{attrs:{"to":"/halls"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Halls ")])])])]}}],null,false,1235960378)}):_vm._e(),(_vm.$global.hasPermission('decorsview'))?_c('router-link',{attrs:{"to":"/decors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Decors ")])])])]}}],null,false,876699500)}):_vm._e(),(_vm.$global.hasPermission('fnbextrasview') || _vm.$global.hasPermission('fnbmenusview') || _vm.$global.hasPermission('fnbpackagesview'))?_c('router-link',{attrs:{"to":"/fnb"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage F&B ")])])])]}}],null,false,2395459618)}):_vm._e(),(_vm.$global.hasPermission('roomsview'))?_c('router-link',{attrs:{"to":"/rooms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Rooms ")])])])]}}],null,false,302520332)}):_vm._e(),(_vm.$global.hasPermission('termconditionsview'))?_c('router-link',{attrs:{"to":"/term/conditions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Term Conditions ")])])])]}}],null,false,3079415118)}):_vm._e(),(_vm.$global.hasPermission('otherchargesview'))?_c('router-link',{attrs:{"to":"/other/charges"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Other Charges ")])])])]}}],null,false,944102509)}):_vm._e(),(_vm.$global.hasPermission('assetsview'))?_c('router-link',{attrs:{"to":"/assets"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Asset ")])])])]}}],null,false,1709164944)}):_vm._e(),(_vm.$global.hasPermission('guestroomsview'))?_c('router-link',{attrs:{"to":"/guest/rooms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Guest Rooms ")])])])]}}],null,false,3238474364)}):_vm._e(),(_vm.$global.hasPermission('invitationattachmentsview'))?_c('router-link',{attrs:{"to":"/event/attachments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Manage Invitations")])])])]}}],null,false,1061901378)}):_vm._e()],1)])]):_vm._e(),(_vm.$global.hasPermission('leadsimport') || _vm.$global.hasPermission('fnbmenusimport'))?_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(['/food/menus/import','/import/leads','/brochures']) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(3),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.$global.hasPermission('fnbmenusimport'))?_c('router-link',{attrs:{"to":"/food/menus/import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage FoodMenu Import ")])])])]}}],null,false,2434726860)}):_vm._e(),(_vm.$global.hasPermission('leadsimport'))?_c('router-link',{attrs:{"to":"/import/leads"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Leads Import ")])])])]}}],null,false,2975234050)}):_vm._e(),(_vm.$global.hasPermission('brochuresview'))?_c('router-link',{attrs:{"to":"/brochures"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Brochures ")])])])]}}],null,false,3895100133)}):_vm._e(),_c('router-link',{attrs:{"to":"/import/bookings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Booking Import ")])])])]}}],null,false,3210246388)})],1)])]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Events ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Users ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Settings ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Import ")])])}]

export { render, staticRenderFns }