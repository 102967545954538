<template>
    <div>
        <ul class="menu-nav">
            <router-link
                to="/dashboard"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Dashboard </span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-show="$global.hasPermission('todolistsview')"
                to="/todo/lists"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> To-Do-List </span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-show="$global.hasPermission('vendorsview')"
                to="/guest/user/vendors"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Vendors </span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-show="$global.hasPermission('subguestusersview')"
                to="/sub/guest/user"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Sub Guest User </span>
                    </a>
                </li>
            </router-link>
            <router-link
                v-if="$global.hasPermission('itinerariesview')"
                to="/itineraries"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Event Schedule </span>
                    </a>
                </li>
            </router-link>
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu menu-item-open-dropdown"
                v-bind:class="{ 'menu-item-active': hasActiveChildren(['/user/company/profiles','/properties','/halls','/decors','/fnb','/rooms','/term/conditions','/other/charges','/assets']) }"
            >
                <a href="#" class="menu-link menu-toggle">
                    <span class="menu-text"> Settings </span>
                </a>
                <div class="menu-submenu menu-submenu-classic">
                    <ul class="menu-subnav">
                        <router-link
                            v-if="$global.hasPermission('guestroomsview')"
                            to="/guest/rooms"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text">Manage Rooms</span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('groupsview')"
                            to="/groups"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text">Manage Group</span>
                                </a>
                            </li>
                        </router-link>
                        <router-link
                            v-if="$global.hasPermission('invitationattachmentsview')"
                            to="/event/attachments"
                            v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                            <li
                                aria-haspopup="true"
                                class="menu-item"
                                :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                            >
                                <a :href="href" class="menu-link" @click="navigate">
                                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                                    <span class="menu-text">Manage Invitation</span>
                                </a>
                            </li>
                        </router-link>
                    </ul>
                </div>
            </li>
            <router-link
                v-show="$global.hasPermission('guestlistsview')"
                to="/guests"
                v-slot="{ href, navigate, isActive, isExactActive }"
            >
                <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']"
                >
                    <a :href="href" class="menu-link" @click="navigate">
                        <span class="menu-text"> Guest Lists </span>
                    </a>
                </li>
            </router-link>
        </ul>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        methods: {
            hasActiveChildren(match) {
                if (_.isArray(match)) {
                    if (_.filter(match, (i) => i === this.$route["path"])[0]) {
                        return _.filter(match, (i) => i === this.$route["path"])[0] !== -1;
                    }
                }
                return this.$route["path"].indexOf(match) !== -1;
            }
        }
    }
</script>
