<template>
    <div>
        <div class="text-left">
            <h5>Your Properties</h5>
            <p class="text-primary" v-if="getActiveProperty">selected property is : {{ getActiveProperty.name }}
            <p>
                <button
                    :disabled="getActiveProperty ? getActiveProperty.id === property.id : false"
                    @click="switchPropertyReload(property.id)" class="btn btn-success btn-sm mr-2 mb-1"
                    v-for="(property,index) in getPropertyList"
                    :key="index">
                    {{ property.name }}
                </button>
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {request} from "@/core/services/Request";
import {getPropertyId} from "@/util/globalFunction";
import {setPropertyId} from "@/util/globalFunction";
import {bus} from "../../../../../main";

export default {
    data() {
        return {
            properties: [],
        }
    },
    mounted() {
        this.$store.dispatch('getPropertyList')

        bus.$on("check-selected-property", () => {
            this.$store.dispatch('getPropertyList')
        })

        if (getPropertyId()) {
            this.getSwitchProperty(getPropertyId())
        }
    },
    methods: {
        getSwitchProperty(Id) {
            request({
                url: `/properties/detail/${Id}`,
                method: 'get',
            })
                .then((response) => {
                    const {data} = response.data
                    this.$store.dispatch('getActiveProperty', data)
                })
                .catch((error) => {
                })
        },
        switchProperty(id) {
            this.getSwitchProperty(id)
        },
        switchPropertyReload(id) {
            setPropertyId(id)
            window.location.href = process.env.BASE_URL
        },
    },
    computed: {
        ...mapGetters({getPropertyList: 'getPropertyList', getActiveProperty: 'getActiveProperty'}),
        ...mapState([
            'global',
        ]),
    }
}
</script>
