var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Dashboard ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('leadsview')),expression:"$global.hasPermission('leadsview')"}],attrs:{"to":"/leads"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Leads ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('eventsview')),expression:"$global.hasPermission('eventsview')"}],attrs:{"to":"/events"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Events ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('calendarsview')),expression:"$global.hasPermission('calendarsview')"}],attrs:{"to":"/calendar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Calendar ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('quotationsview')),expression:"$global.hasPermission('quotationsview')"}],attrs:{"to":"/quotations"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Quotation ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('quotationbookingsview')),expression:"$global.hasPermission('quotationbookingsview')"}],attrs:{"to":"/bookings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Booking ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('sharebounceeventsview')),expression:"$global.hasPermission('sharebounceeventsview')"}],attrs:{"to":"/spu/share/bounce/events"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" MarketPlace ")])])])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(['/sub/users','/guest/users']) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(0),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('subusersview')),expression:"$global.hasPermission('subusersview')"}],attrs:{"to":"/sub/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" SubUser ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('masterguestsview')),expression:"$global.hasPermission('masterguestsview')"}],attrs:{"to":"/guest/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v("Master Guest User ")])])])]}}])}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('subguestusersview')),expression:"$global.hasPermission('subguestusersview')"}],attrs:{"to":"/sub/guest/user"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Sub Guest User ")])])])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(['/company/profiles','/properties','/halls','/decors','/fnb','/fnb/packages','/rooms','/term/conditions','/other/charges','/assets','/fnb/food/menus']) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.$global.hasPermission('companyprofilesview'))?_c('router-link',{attrs:{"to":"/user/company/profiles"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Company Profiles ")])])])]}}],null,false,1650820841)}):_vm._e(),(_vm.$global.hasPermission('propertiesview'))?_c('router-link',{attrs:{"to":"/properties"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Properties ")])])])]}}],null,false,444258422)}):_vm._e(),(_vm.$global.hasPermission('hallsview'))?_c('router-link',{attrs:{"to":"/halls"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Halls ")])])])]}}],null,false,3537485293)}):_vm._e(),(_vm.$global.hasPermission('decorsview'))?_c('router-link',{attrs:{"to":"/decors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Decors ")])])])]}}],null,false,4016172667)}):_vm._e(),(_vm.$global.hasPermission('fnbextrasview') || _vm.$global.hasPermission('fnbmenusview') || _vm.$global.hasPermission('fnbpackagesview'))?_c('router-link',{attrs:{"to":"/fnb"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage F&B ")])])])]}}],null,false,1161073397)}):_vm._e(),(_vm.$global.hasPermission('roomsview'))?_c('router-link',{attrs:{"to":"/rooms"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Rooms ")])])])]}}],null,false,2878854555)}):_vm._e(),(_vm.$global.hasPermission('termconditionsview'))?_c('router-link',{attrs:{"to":"/term/conditions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Term Conditions ")])])])]}}],null,false,826253337)}):_vm._e(),(_vm.$global.hasPermission('otherchargesview'))?_c('router-link',{attrs:{"to":"/other/charges"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Other Charges ")])])])]}}],null,false,3600685658)}):_vm._e(),(_vm.$global.hasPermission('assetsview'))?_c('router-link',{attrs:{"to":"/assets"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Asset ")])])])]}}],null,false,3778851719)}):_vm._e()],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu menu-item-open-dropdown",class:{ 'menu-item-active': _vm.hasActiveChildren(['/food/menus/import','/import/leads','/brochures']) },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(2),_c('div',{staticClass:"menu-submenu menu-submenu-classic"},[_c('ul',{staticClass:"menu-subnav"},[(_vm.$global.hasPermission('fnbmenusimport'))?_c('router-link',{attrs:{"to":"/food/menus/import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage FoodMenu Import ")])])])]}}],null,false,3797980379)}):_vm._e(),(_vm.$global.hasPermission('leadsimport'))?_c('router-link',{attrs:{"to":"/import/leads"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Leads Import ")])])])]}}],null,false,1821868501)}):_vm._e(),(_vm.$global.hasPermission('brochuresview'))?_c('router-link',{attrs:{"to":"/brochures"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(" Manage Brochures ")])])])]}}],null,false,2580662738)}):_vm._e()],1)])]),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.$global.hasPermission('guestlistsview')),expression:"$global.hasPermission('guestlistsview')"}],attrs:{"to":"/guests"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Guest Lists ")])])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Users ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Settings ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Import ")])])}]

export { render, staticRenderFns }