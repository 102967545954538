var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topbar-item"},[_c('div',{staticClass:"btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto",attrs:{"id":"kt_quick_user_toggle"}},[_c('div',{staticClass:"d-flex flex-column text-right pr-3"},[_c('span',{staticClass:"text-white font-weight-bolder font-size-sm d-none d-md-inline"},[_vm._v(" "+_vm._s(_vm.profileData ? _vm.profileData.name : "")+" ")]),(_vm.$global.hasPermission('selectedpropertiesview') && _vm.getActiveProperty)?_c('span',[_c('small',[_vm._v(" "+_vm._s(_vm.getActiveProperty.name)+" ")])]):_vm._e()]),_c('span',{staticClass:"symbol symbol-35"},[_c('span',{staticClass:"symbol-label font-size-h5 font-weight-bold text-white bg-white-o-30"},[_vm._v(" "+_vm._s(_vm.profileData ? _vm.profileData.name.charAt(0).toUpperCase() : "")+" ")])])]),_c('div',{ref:"kt_quick_user",staticClass:"offcanvas offcanvas-right p-10",attrs:{"id":"kt_quick_user"}},[_vm._m(0),_c('perfect-scrollbar',{staticClass:"offcanvas-content pr-5 mr-n5 scroll",staticStyle:{"max-height":"90vh","position":"relative"}},[_c('div',{staticClass:"d-flex align-items-center mt-5"},[_c('div',{staticClass:"symbol symbol-100 mr-5"},[(_vm.profile)?_c('div',[_c('img',{staticClass:"symbol-label",attrs:{"src":_vm.profile,"alt":""}}),_c('i',{staticClass:"symbol-badge bg-success"})]):_vm._e()]),_c('div',{staticClass:"d-flex flex-column"},[_c('router-link',{attrs:{"to":"/profile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.profileData ? _vm.profileData.name : "")+" ")])]}}])}),_c('div',{staticClass:"navi mt-2"},[_c('a',{staticClass:"navi-item",attrs:{"href":"#"}},[_c('span',{staticClass:"navi-link p-0 pb-2"},[_c('span',{staticClass:"navi-icon mr-1"},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-icon-primary"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Communication/Mail-notification.svg"}})],1)]),_c('span',{staticClass:"navi-text text-muted text-hover-primary"},[_vm._v(" "+_vm._s(_vm.profileData ? _vm.profileData.email : "")+" ")])])])]),_c('button',{staticClass:"btn btn-light-primary btn-bold",on:{"click":_vm.onLogout}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.loader),expression:"loader"}],staticClass:"fa fa-spin fa-spinner"}),_vm._v(" Sign out ")])],1)]),(_vm.$global.hasPermission('walletsview'))?_c('div',[_c('hr'),_c('router-link',{attrs:{"to":"/my/wallet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var href = ref.href;
                            var navigate = ref.navigate;
                            var isActive = ref.isActive;
                            var isExactActive = ref.isExactActive;
return [_c('a',{class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" My Wallet ")])]}}],null,false,1592612279)})],1):_vm._e(),_c('hr'),(_vm.$global.hasPermission('owncompanyprofilesview') || _vm.$global.hasPermission('companyprofilesview'))?_c('property'):_vm._e(),(_vm.$global.hasPermission('companyprofilesview') ||_vm.$global.hasPermission('masterguestuser') || _vm.$global.hasPermission('subguestuser'))?_c('event'):_vm._e()],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offcanvas-header d-flex align-items-center justify-content-between pb-5"},[_c('h3',{staticClass:"font-weight-bold m-0"},[_vm._v(" User Profile ")]),_c('a',{staticClass:"btn btn-xs btn-icon btn-light btn-hover-primary",attrs:{"href":"#","id":"kt_quick_user_close"}},[_c('i',{staticClass:"ki ki-close icon-xs text-muted"})])])}]

export { render, staticRenderFns }